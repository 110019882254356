<template>
  <card card-body-classes="table-full-width">
    <form @submit.prevent="submit">
      <el-row>
        <el-col>
          <base-input :required="true" label="Assunto do e-mail" v-model="form.title"></base-input>
        </el-col>
        <el-col>
          <label>Variáveis disponíveis</label><br/>
          <div class="border emailPreview">
            <template v-for="(item, index) in form.variables">
              <el-tooltip :key="index" class="item" :content="item.description" placement="top">
                <span :key="index" v-if="form.variables.length-1 > index">{{item.variable}}, </span>
                <span :key="index" v-else>{{item.variable}}</span>
              </el-tooltip>
            </template>
          </div>
        </el-col>
        <el-col class="mt-3">
          <label>Corpo do e-mail</label>
          <vue-editor v-model="form.body" :editorToolbar="customToolbar"></vue-editor>
        </el-col>
        <el-col class="mt-3">
          <label>Pré-visualização do e-mail</label>
          <div class="border emailPreview" v-html="previewEmail"></div>
        </el-col>
        <el-col class="mt-3" v-if="!loading">
          <label class="mr-3 mb-0">Status</label>
          <el-radio-group v-model="form.enabled">
            <el-radio class="mb-0" :label="true">Ativo</el-radio>
            <el-radio class="mb-0" :label="false">Inativo</el-radio>
          </el-radio-group>
        </el-col>
        <!-- botoes -->
        <el-col class="mt-3">
          <base-button @click.prevent.stop="$router.go(-1)" type="danger">Cancelar</base-button>
          <base-button native-type="submit" type="success" class="ml-3">Salvar</base-button>
        </el-col>
      </el-row>
    </form>
  </card>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import swal from 'sweetalert2'
import axios from 'axios'
export default {
  name: 'form',
  components: {
    VueEditor
  },
  data () {
    return {
      form: {
        enabled: 0,
        body: ''
      },
      loading: true,
      previewEmail: '',
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link']
      ]
    }
  },
  async mounted () {
    const response = await axios.get('/emailtemplate/' + this.$route.params.id, {
      headers: {
        Authorization: this.$jwt.getAccessToken()
      }
    })
    this.form = response.data
  },
  methods: {
    validate () {
      const form = this.form
      if (!form.title || !form.title.trim()) return false
      if (!form.body || !form.body.trim()) return false
      return true
    },
    async submit () {
      if (this.validate()) {
        const response = await axios.post('/emailtemplate/', {
          id: this.form.id,
          enabled: this.form.enabled,
          title: this.form.title,
          body: this.form.body
        }, {
          headers: {
            Authorization: this.$jwt.getAccessToken(),
            'Content-Type': 'application/json'
          }
        })

        if (response && response.status === 200) {
          await swal.fire({
            title: 'Adicionado!',
            text: 'Template adicionado com sucesso.',
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            this.$router.push('/emails')
          })
        } else {
          await swal.fire({
            title: 'Erro!',
            text: 'Ocorreu um erro, tente novamente mais tarde.',
            denyButtonText: 'Ok',
            showDenyButton: true,
            showConfirmButton: false
          })
        }
      } else {
        await swal.fire({
          title: 'Erro!',
          text: 'Por favor, preencha todos os campos obrigatórios.',
          denyButtonText: 'Ok',
          showDenyButton: true,
          showConfirmButton: false
        })
      }
    },
    emailPreview () {
      this.previewEmail = this.form.body
      this.form.variables.forEach((item, index) => {
        if (this.previewEmail.includes(item.variable)) {
          this.previewEmail = this.previewEmail.replaceAll(item.variable, item.example)
        }
      })
    }
  },
  watch: {
    'form.body': {
      handler () {
        this.emailPreview()
        this.loading = false
      }
    }
  }
}
</script>

<style>
.emailPreview{
  border-radius: 0.4rem;
  padding: 10px 18px 10px 18px;
}
.emailPreview,
.ql-toolbar.ql-snow,
.ql-container.ql-snow{
  border-color: rgba(29, 37, 59, 0.5) !important;
}
.ql-container.ql-snow{
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}
.ql-toolbar.ql-snow{
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}
</style>
